<template>
  <div class="mt-4">
    <div class="table-responsive">
      <div class="list-group">
        <div class="row d-flex justify-content-between list-group-item list-group-item-action" style="background: #212529; color: white; font-weight: bold;">
          <div class="col-1"></div>
          <div class="col-3">
            {{ $t('cms.table.header.key') }}
          </div>
          <div class="col-1">
            {{ $t('cms.table.header.locale') }}
          </div>
          <div class="col-2">
            {{ $t('cms.table.header.slug') }}
          </div>
          <div class="col-2">
            {{ $t('cms.table.header.title') }}
          </div>
          <div class="col-3">
            {{ $t('cms.table.header.actions') }}
          </div>
        </div>
      </div>

      <div v-if="!resourcesCopy || !resourcesCopy.length" class="text-center mt-3">{{ $t('cms.table.no_results') }}</div>

      <div v-if="!isLoading && resourcesCopy" class="list-group">
        <draggable :list="resourcesCopy" @end="onEnd($event)">
          <div v-for="(element, index) in resourcesCopy" :key="element.id" class="row d-flex list-group-item list-group-item-action" :class="{ 'bg-light': index % 2 !== 0 }">
            <div class="col-1 d-flex align-items-center">
              <b-icon-grip-vertical class="h3 mb-0" role="button"></b-icon-grip-vertical>
            </div>
            <div class="col-3 d-flex align-items-center">
              {{ element.keyName }}
            </div>
            <div class="col-1 d-flex align-items-center">
              {{ element.locale }}
            </div>
            <div class="col-2 d-flex align-items-center">
              {{ element.slug }}
            </div>
            <div class="col-2 d-flex align-items-center">
              {{ element.title }}
            </div>
            <div class="col-3">
              <div class="d-flex align-items-center">
                <b-icon-arrow-up-square-fill
                    class="mr-2"
                    style="cursor: pointer"
                    font-scale="2.2"
                    variant="warning"
                    @click="moveResource('up', element.id)"
                >
                </b-icon-arrow-up-square-fill>
                <b-icon-arrow-down-square-fill
                    class="mr-2"
                    style="cursor: pointer"
                    font-scale="2.2"
                    variant="warning"
                    @click="moveResource('down', element.id)"
                >
                </b-icon-arrow-down-square-fill>
                <router-link class="btn btn-primary btn-sm"
                             :to="{ name: 'cms-general', params: { id: element.id }}"> {{$t('cms.table.btn.edit')}}
                </router-link>
              </div>
            </div>
          </div>
        </draggable>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    isCreateType: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      resourcesCopy: null
    }
  },
  mounted() {
    if(!this.isCreateType) {
      this.$store.dispatch('cms/getResourceChildElements', this.$route.params.id);
    }

    this.setResourcesCopy();
  },
  watch: {
    resourceChildElements() {
      this.setResourcesCopy();
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.cms.isLoading,
      resourceChildElements: (state) => state.cms.resourceChildElements
    }),
  },
  methods: {
    setResourcesCopy() {
      this.resourcesCopy = this.resourceChildElements ? [...this.resourceChildElements] : null;
    },
    onEnd(e) {
      const parentId = this.resourcesCopy[e.newIndex-1] ? this.resourcesCopy[e.newIndex-1].id : -1;

      const payload = {
        id: Number(this.resourcesCopy[e.newIndex].id),
        direction: parentId >= 0 ? 'next_of' : 'first',
        nextOf: parentId
      }

      this.$store.dispatch('cms/moveResource', payload).then(() => {
        this.$store.dispatch('cms/getResourceStructure');
      });
    },
    moveResource(direction, id) {
      const payload = {
        id: Number(id),
        direction: direction
      }

      this.$store.dispatch('cms/moveResource', payload).then(() => {
        this.$store.dispatch('cms/getResourceStructure');
        this.$store.dispatch('cms/getResourceChildElements', this.$route.params.id);
      });
    }
  }
}
</script>